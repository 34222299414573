.item {
  margin-bottom: 16px;

  @media screen {
    width: 50vw;
  }

  @media screen and (orientation: portrait) {
    width: 75vw;
  }

  @media screen and (orientation: landscape) {
    width: 70vh;
  }
}
