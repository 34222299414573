.item {
  margin-bottom: 16px;
  max-width: 50vw;

  @media screen and (orientation: portrait) {
    max-width: 75vw;
  }

  @media screen and (orientation: landscape) {
    max-width: 70vh;
  }
}

canvas {
  max-width: 100%;
  max-height: 100%;
  image-rendering: pixelated;
}

.meltIcon {
  cursor: pointer;
}
