.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 8px 64px 8px;
}

.card {
  float: left;
  margin: 2vw;
}

.card {
  width: 20vw;
  min-width: 256px;
}

.item {
  height: 20vw;
  min-height: 256px;
}

.container {
  display: flex;
  flex-direction: column;

  &:hover .item {
    transform: scale(1.05);
  }
}

.item {
  transition: transform 200ms;
}

.meta {
  margin-top: 16px;
}

.floatingButton {
  position: fixed;
  bottom: 12px;
  right: 24px;
  box-shadow: 4px 4px 6px #999;
}
