.tool {
  padding: 0;

  &.selected {
    border: 1px solid black;
  }
}

.beadTool {
  width: 24px;
  height: 24px;
  padding: 1px;
  cursor: pointer;
  box-sizing: border-box;

  &.selected {
    border: 1px solid black;
  }
}

.beadToolContainer {
  padding: 4px;
}

.modifiers {
  margin-left: auto;
}

.container {
  user-select: none;
  width: 50vw;

  @media screen and (orientation: portrait) {
    width: 65vw;
  }

  @media screen and (orientation: landscape) {
    width: 60vh;
  }
}

.beadPattern {
  cursor: none;
}

.cursor {
  display: flex;
  position: fixed;
  top: -1000px;
  left: -1000px;
  pointer-events: none;
}

.section {
  margin-top: 12px;
}
