.svg {
  width: 100%;
  height: 100%;
}

.image {
  max-width: 100%;
  max-height: 100%;
}

.image,
.svg {
  display: block;
}

.svg.hidden {
  width: 0;
  height: 0;
  position: absolute;
}

.placeholder {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
